<template>
  <div>
    <SimpleHeader @back="() => navigateTo('/')" />
    <div class="columns">
      <nav class="sidebar is-narrow hero is-fullheight">
        <SettingsSidebar />
      </nav>
      <main class="column">
        <slot />
      </main>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sidebar {
  border-right: 1px solid hsl(0, 0%, 90%);
}

.columns {
  margin: 0;
}
</style>
