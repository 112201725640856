<script setup lang="ts">
const route = useRoute()
const organizationId = Number(route.params.organizationId)
const locationId = Number(route.params.locationId)
</script>

<template>
  <aside class="root">
    <BaseSidebar
      :title="$t('詳細設定')"
      :menus="[
        {
          to: {
            name: 'organizationId-locations-locationId-timeboxes',
            params: { organizationId, locationId },
          },
          text: $t('実施タイミング'),
        },
        {
          to: {
            name: 'organizationId-locations-locationId-checkFileLabels',
            params: { organizationId, locationId },
          },
          text: $t('チェックファイルラベル'),
        },
        {
          to: {
            name: 'organizationId-locations-locationId-checkSheetLabels',
            params: { organizationId, locationId },
          },
          text: $t('チェックシートラベル'),
        },
        {
          to: {
            name: 'organizationId-locations-locationId-noteCategories',
            params: { organizationId, locationId },
          },
          text: $t('共有メモ分類'),
        },
        {
          to: {
            name: 'organizationId-locations-locationId-ancillaryWorks',
            params: { organizationId, locationId },
          },
          text: $t('付帯作業内容'),
        },
      ]"
    />
  </aside>
</template>

<style scoped lang="scss">
.root {
  width: 260px;
}
</style>
